$primaryBlue: #0b44d8;
$secondaryBlue: #0578ff;
$whiteColor: #ffffff;
$primaryFontColor: #1c1c1c;
$secondaryFontColor: #3e3e3e;
$tertiaryFontColor: #808080;

$lightCoolColor: #f4f7fb;

$dividerColor: #e5e5e5;
$errorRed: #eb1260;

$screen-sm-min: 575px; // Small tablets and large smartphones (landscape view)
$screen-md-min: 767px; // Small tablets (portrait view)
$screen-lg-min: 991px; // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops
