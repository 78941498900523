@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.all-teams-main-div {
  padding: 0 1rem 0 2rem;
}
.all-teams-timeline-select-dropdown {
  .dropdown-toggle::after {
    border: none;
  }
}
.all-teams-timeline-select-button {
  width: 120px !important;
  border: 1px solid black !important;
  border-radius: 2rem !important;
}
.all-teams-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.manage-teams-button {
  width: 206px;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
}
.all-teams-quick-select-btn {
  width: 110px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}
.all-teams-search-input {
  width: 235px;
  background: #f4f7fb;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.black-tick-circle {
  width: 16px;
  height: 16px;
}
.all-team-pos-bottom {
  position: fixed !important;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  bottom: -12px !important;
  max-height: 80vh !important;
}
.margin-0-imp {
  margin: 0 !important;
}
.round-red-dot {
  width: 5px;
  height: 5px;
  background: #eb1260;
}

@include media-breakpoint-down(sm) {
  .all-teams-search-div {
    width: 100%;
  }
  .all-teams-search-input {
    width: 100%;
  }
}
