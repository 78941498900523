.page-filter-button {
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important ;
  border-radius: 20px !important;
  font-weight: 400;
  font-size: 12px !important;
  color: #1c1c1c !important;
  min-width: 100px !important;
  text-align: center !important;
}
