@import "../../../index.scss";
.profile-box-shadow {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 11px;
  h4 {
    color: #1c1c1c;
    font-weight: 600;
    font-size: 16px;
  }
}
.logo-border-box {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 17px 18px;
  @include media-breakpoint-down(sm) {
    padding: 10px 11px;
  }
}
.edit-btn-padding {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 11px;
  margin-top: -5px !important;
  margin-right: -5px !important;
}
.edit-logo-small {
  border: 1px solid #e5e5e5;
  background-color: white;
  border-radius: 4px;
  padding: 0px 5px;
  bottom: 2px;
  right: 2px !important;
}

.blue-badge {
  color: #0578ff;
  background: #eff6ff;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 12px;
}

.company-logo-dialog {
  background: #e6bbca;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
}
.img-dotted-border {
  border: 2px dashed #b9b9b9;
}
.potential-image-wrapper {
  border-radius: 8px;
}

.btn-dialog-cancel {
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  width: 100px;
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  color: #1c1c1c;
}
.btn-dialog-save {
  background: #1c1c1c;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  width: 100px !important;
  height: 40px !important;
  color: #ffffff;
}
.text-black-medium {
  font-weight: 400;
  font-size: 13px;
  color: #4f4f4f;
  text-decoration-line: underline;
}
.min-width-box {
  width: 280px !important;
}
.modal-profile-picture-div {
  height: 163px;
  width: 163px;
  position: relative;
}
.profile-pic {
  display: block;
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
  background: gray;
}

.profile-image-resolution {
  width: -moz-available;
  object-fit: contain;
  background: gray;
}
@media screen and (max-width: 400px) {
  .company-profile-alignment {
    flex-direction: column !important;
  }
  .modal-fullscreen-lg-down {
    width: 100% !important;
  }
}
.fixed-height-img-container {
  min-height: 80px !important;
  background: #f4f7fb !important;
  border-radius: 8px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar-wrapper {
  // @include media-breakpoint-down(sm) {
  //   width: 100% !important;
  // }
  .seekbar-wrapper {
    margin-top: 5px;
    max-width: 268px !important;
    .seekbar {
      background-color: rgba(56, 56, 56, 0.13);
      height: 8px;
      @include border-radius(20px, 20px, 20px, 20px);
    }
    .progress-bar {
      background: linear-gradient(
        271.91deg,
        #d80bab 5.92%,
        #086afd 95.35%
      ) !important;
      border-radius: 24px;
    }
  }
}

::placeholder {
  color: #b9b9b9;
}

.input-color-black {
  color: #3e3e3e !important;
}

.truncate-string {
  text-overflow: ellipsis;
  overflow: hidden;
  // whitespace: no-wrap;
}

.company-pf-section-width {
  width: 200px;
  @include media-breakpoint-down(md) {
    width: 150px;
  }
  @include media-breakpoint-down(sm) {
    width: 140px;
  }
}
