@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

@media screen and (max-width: 768px) {
  .mobile-margin {
    margin: 0rem 2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .mobile-margin {
    margin: 0px 10px 0px 10px !important;
  }
}

::placeholder {
  color: #b9b9b9 !important;
}

.invite-btn-position {
  position: absolute;
  bottom: 8px;
  right: 0;
  @include media-breakpoint-down(sm) {
    position: inherit;
  }
}

.btn-invite-referee-invite {
  width: 172px !important;
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  background: #1c1c1c;
  border-radius: 8px;
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.icon-style {
  max-height: 16px;
  max-width: 16px;
}
