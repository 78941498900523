@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.individual-teams-container {
  padding: 0 1rem 0 3rem;
}
.team-filter-btn {
  height: 28px;
  width: 110px;
  border-radius: 1rem;
}
.team-job-info {
  height: 110px;
}
.teams-action-card-position {
  top: -6px !important;
  right: 0px !important;
  // left: calc(100% - 155px);
  left: auto !important;
  // bottom: 0px !important;
}
.team-selection-dropdown {
  .dropdown-toggle::after {
    border: none;
  }
  .dropdown-menu {
    margin-top: -1px !important;
    border-color: #e5e5e5 !important;
  }
  .drop-down-arrow {
    position: relative;
    width: 12px;
    left: 5px;
    top: 10px;
  }
}
.small-cards {
  height: 132px;
  // width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}
.color-green {
  color: #12bd6b;
}
.timeline-select-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}
.timeline-select-button {
  min-width: 112px !important;
  padding: 1rem !important;
  border: 1px solid black !important;
  border-radius: 2rem !important;
  // @include media-breakpoint-down(sm) {
  //   width: 111px !important;
  // }
}
.custome-date-box-position {
  position: absolute;
  right: 0px;
  top: 30px;
}
.custome-date-box {
  background-color: white;
  border: 1px solid #e5e5e5;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.dropdown-icon-span {
  width: 15px;
}

@include media-breakpoint-down(lg) {
  .individual-teams-container {
    padding: 0rem 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .back-btn-display {
    display: none;
  }
  // .individual-back-btn-pos {
  //   position: absolute;
  //   left: 60px;
  // }
  .individual-teams-container {
    padding: 0;
  }
  .individual-teams-small-cards-div {
    padding: 0 0.75rem;
  }
  .individual-back-btn-pos {
    position: absolute;
    left: 22px;
  }
}

// @media (max-width: 350px) {
//   .individual-teams-container {
//     padding: 0;
//   }
//   .individual-teams-small-cards-div {
//     padding: 0 0.75rem;
//   }
//   .individual-back-btn-pos {
//     left: 15px;
//   }
// }
