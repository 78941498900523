@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.support-and-feedback-container {
  padding: 0rem 1rem 0rem 3rem;
}
.support-feedback-input {
  // width: 310px;
  height: 40px;
}
.support-feedback-input-border {
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}
.send-feedback-btn {
  width: 183px;
  height: 56px;
}
.feedback-form-width {
  width: 50%;
}

@include media-breakpoint-down(lg) {
  .support-and-feedback-container {
    padding: 0 0 0 7px;
  }
}
@include media-breakpoint-down(sm) {
  .feedback-form-width {
    width: 100%;
  }
  .support-and-feedback-container {
    padding: 0 0 0 3px;
  }
}
