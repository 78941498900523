.profile-pic {
  display: block;
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
  background: gray;
}

.profile-image-resolution {
  // height: -webkit-fill-available;
  // width: -webkit-fill-available;
  width: -moz-available;
  // height: -moz-available;
  object-fit: contain;
  background: gray;
}
