.navigation-sidebar {
  position: fixed;
  top: 58px;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
  background: #f4f7fb;
}

.navigation-sidebar-background {
  background: #f4f7fb !important;
  width: 300px !important;
}

.sidebar-div {
  color: #3e3e3e !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 150%;
  text-decoration: none;
  padding: 17px 50px;
  padding-right: 15px;
}
.sidebar-div.active {
  font-weight: 700;
  color: #1c1c1c;
  background-color: white;
}

.filter-sidebar-div {
  color: #1c1c1c !important;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  padding: 5px 10px;
}
.filter-sidebar-div.active {
  font-weight: 700;
  color: #1c1c1c;
  background: #f4f7fb !important;
}
.navigation-mobile-responsive {
  width: 320px !important;
}
.navigation-content{
  margin-left: 300px;
  @media screen and (max-width: "991px") {
    margin-left: 0px;
  }
}
@media screen and (max-width: "350px") {
  .navigation-mobile-responsive {
    width: 100% !important;
  }
}
.navigation-sidebar-mobile {
  background: #f4f7fb !important;
  width: 320px !important;
  img {
    height: 12px;
    // width: 13.35px;
  }
}
