@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.container-member-teams {
  margin: 2rem 2rem 1rem 3rem;
}
.container-member-teams-detail {
  margin: 1rem 1rem 1rem 2rem;
}

@include media-breakpoint-down(lg) {
  .member-detail-main {
    width: 100% !important;
  }
}
@include media-breakpoint-up(lg) {
  .member-detail-main {
    width: 80% !important;
  }
}
