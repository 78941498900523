@import "./variables.scss";
@import "./mixins.scss";
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.container {
  max-width: 1300px !important;
  padding-top: 30px !important;
}

.body-container {
  & > .container {
    padding-left: 0px;
    padding-right: 0px;
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@include media-breakpoint-between(sm, xl) {
  .container {
    max-width: none;
    padding: 0px;
  }
}
@include media-breakpoint-down(sm) {
  padding: 5px;
}
// @media screen and (max-width: "400px") {
//   .container {
//     padding: 10px !important;
//     padding-left: 30px !important;
//   }
// }

.flex-1 {
  flex: 1;
}

input:focus-visible {
  outline: none;
}

/* ========== Background Start =========*/
.light-cool-bg {
  background-color: $lightCoolColor;
}
/* ========== Background End =========*/

/* ========== Badge Start =========*/
.badge.custom-red {
  background-image: linear-gradient(#eb1260, #ff3d12);
  font-size: 11px;
  font-weight: 500;
  padding: 0.32em 0.85em;
}

/* ========== Badge End =========*/

/* ========== Buttons Start =========*/
.wired-dark-button {
  .btn {
    font-size: 12px;
    font-weight: 700;
    color: $primaryFontColor;
    padding: 10px 50px;
    background-color: $whiteColor;
    @include transition(200ms);
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      background-color: #f1f1f1;
    }
    img {
      margin-right: 5px;
    }
    &.btn-outline-dark {
      border-color: $primaryFontColor;
      @include border-radius(8px, 8px, 8px, 8px);
    }
  }
}

.switch-widget {
  background-color: $whiteColor !important;
  border: 1px solid #0b44d8;
  @include border-radius(50px, 50px, 50px, 50px);
  padding: 2px;
  overflow: hidden;
  .option {
    border: none;
    background: none;
    font-weight: 700;
    padding: 7px 20px;
    color: $primaryBlue;
    min-width: 150px !important;
    @include border-radius(50px, 50px, 50px, 50px);
    @include transition(200ms);
    &.active {
      background-color: #0b44d8;
      background-image: linear-gradient(to bottom right, #0578ff, #0b44d8);
      color: $whiteColor;
    }
    @include media-breakpoint-down(sm) {
      padding: 7px 15px;
      min-width: auto !important;
    }
  }
  &.black {
    border-color: #1c1c1c;
    background-color: white !important;
    .option {
      color: $primaryFontColor;
      &.active {
        background-color: #1c1c1c;
        background-image: linear-gradient(to bottom right, #1c1c1c, #000000);
        color: $whiteColor;
      }
    }
  }
}

/* ========== Buttons End =========*/

/* ========== Cards Start =========*/
.job-card {
  position: relative;
  background-color: $whiteColor;
  padding: 20px;
  border: 1px solid #e5e5e5;
  line-height: normal;
  margin-bottom: 25px;
  overflow: hidden;
  @include border-radius(8px, 8px, 8px, 8px);
  @include transition(500ms);

  .job-tag {
    font-size: 12px;
    font-weight: 400;
    padding: 3px 10px;
    margin-right: 5px;
    margin-bottom: 8px;
    @include border-radius(4px, 4px, 4px, 4px);
    background-color: #f8f8f8;
    color: $secondaryFontColor;
  }

  .why-look-job {
    margin-top: 10px;
    .title {
      font-size: 12px;
      font-weight: 400;
      color: $secondaryFontColor;
    }
    .description {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #808080;
    }
  }

  .action {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 8px;
    background-color: #f1f1f1;
    padding: 15px;
    border: 1px solid #e5e5e5;
    opacity: 0;
    @include border-radius(4px, 4px, 4px, 4px);
    @include transition(400ms);
    button.blue-gradient {
      font-size: 12px;
      font-weight: 400;
      background-color: #0b44d8;
      background-image: linear-gradient(to bottom right, #0578ff, #0b44d8);
      color: $whiteColor;
      @include border-radius(50px, 50px, 50px, 50px);
      padding: 0.2rem 1rem;
      &:focus {
        box-shadow: none;
      }
    }
    .link {
      a {
        font-size: 14px;
        font-weight: 700;
        color: $primaryFontColor;
      }
    }
  }

  .save-job-action {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .job-save-button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  .applied-info {
    margin-top: 12px;
    .applied-date {
      font-size: 12px;
      font-weight: 300;
      color: #7c7c7c;
    }
    .applied-status {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
      color: $secondaryFontColor;
      &.text-gray {
        color: #b9b9b9;
      }
    }
  }

  .all-job-action {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgb(255, 255, 255)
    );
    padding: 100px 20px 20px 20px;
    opacity: 0;
    @include transition(400ms);
    button {
      font-size: 12px;
      font-weight: 700;
      padding: 10px;
      border-radius: 8px 8px 8px 8px;
    }
    .btn-outline-dark {
      background-color: #fff;
      &:hover {
        background-color: #fff;
        color: $primaryFontColor;
      }
    }
  }

  &:hover {
    border-color: #808080;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    .action,
    .all-job-action {
      opacity: 1;
    }
  }
  @include sm {
    margin-bottom: 10px;
  }
}
.job-card,
.featured-job-card {
  .job-other-info {
    margin-top: 10px;
    min-height: 55px;
    .location,
    .package,
    .experience {
      font-size: 12px;
      font-weight: 400;
      padding: 2px 8px 2px 6px;
      margin-right: 5px;
      margin-bottom: 8px;
      @include border-radius(4px, 4px, 4px, 4px);
    }
    .location {
      background-color: #eff6ff;
      color: $secondaryBlue;
    }
    .package {
      background-color: #fff7fa;
      color: #eb1260;
    }
    .experience {
      background-color: #f8f8f8;
      color: $secondaryFontColor;
    }
    &.refer {
      height: 85px;
    }
  }
}

.company-details-wrapper {
  .company-logo-wrapper {
    width: 72px;
    height: 53px;
    .company-logo {
      width: 52px;
      height: 52px;
      border: 1px solid #e5e5e5;
      @include border-radius(4px, 4px, 4px, 4px);
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .company-name {
    padding-left: 12px;
    font-size: 14px;
    font-weight: 600;
    color: $secondaryFontColor;
    line-height: 19px;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #7c7c7c;
    }
  }
  &.flex-column {
    align-items: flex-start !important;
    .company-name {
      padding-left: 0px;
      margin-top: 10px;
    }
  }
}

.job-referred-by-wrapper {
  border-top: 1px solid #e5e5e5;
  margin-top: 15px;
  padding-top: 8px;
  p {
    margin: 0;
  }
}
.referred-person-wrapper {
  margin-top: 14px;
  .referred-person-pic-wrpper {
    width: 36px;
    .referred-person-pic {
      width: 36px;
      height: 36px;
      @include border-radius(50px, 50px, 50px, 50px);
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .referred-person-details {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    .name {
      color: $secondaryFontColor;
    }
  }
}
/* ========== Cards End =========*/

/* ========== Featured Job thumb Card Start =========*/
.featured-job-card {
  min-height: 400px;
  // background: url("./../assests/images/job-featured.jpg")
  //   no-repeat top left;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  @include border-radius(8px, 8px, 8px, 8px);
  overflow: hidden;
  position: relative;
  color: #ffffff;
  padding: 30px;
  margin-bottom: 25px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.86));
  }
  .card-tag-and-save {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;
    .save-job-action {
      background-color: rgba(0, 0, 0, 0.3);
      padding: 2px 2px;
      @include border-radius(4px, 4px, 4px, 4px);
    }
    .job-save-button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
    }
  }
  .job-company-logo-wrapper {
    min-height: 130px;
    .job-company-logo {
      min-height: 20px;
      min-width: 20px;
      display: inline-block;
      @include border-radius(8px, 8px, 8px, 8px);
    }
  }
  .job-content {
    position: relative;
    z-index: 1;
    .sub-heading {
      font-size: 16px;
      font-weight: 700;
    }
    .heading {
      margin: 0;
      font-size: 32px;
      font-weight: 700;
    }
    .job-other-info {
      min-height: auto;
      margin-top: 22px;
      .location {
        background-color: rgba(239, 246, 255, 0.8);
      }
      .experience {
        background-color: rgba(248, 248, 248, 0.8);
      }
      .package {
        background-color: rgba(255, 247, 250, 0.8);
      }
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      border-top: 1px solid rgba(217, 217, 217, 0.4);
      padding-top: 15px;
      margin-top: 10px;
    }
  }
  .feature-job-actions {
    position: absolute;
    z-index: 2;
    background-color: #1c1c1c;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 25px;
    border-top: 1px solid #808080;
    opacity: 0;
    @include transition(400ms);
    button {
      font-size: 12px;
      font-weight: 700;
      padding: 10px;
      @include border-radius(8px, 8px, 8px, 8px);
      &:focus {
        box-shadow: none;
      }
    }
  }
  &:hover {
    .feature-job-actions {
      opacity: 1;
    }
  }
}

.recom-featured-applied-btn:disabled {
  background-color: white;
  color: black;
}

.alljobs-applied-btn:focus {
  background-color: white;
  color: black;
}

.all-jobs {
  .featured-job-card,
  .job-card {
    min-height: 410px;
  }
}
/* ========== Featured Job thumb Card End =========*/

/* ========== Toaster Start =========*/
.theme-toaster-popup {
  padding: 10px 10px 10px 10px !important;
  border-left: 6px solid #fff !important;
  background-color: #1c1c1c !important;
  color: #f1f1f1 !important;
  &.success {
    border-color: $primaryBlue !important;
  }
  &.alert {
    border-color: #eb1260 !important;
  }

  .swal2-timer-progress-bar {
    background: rgba(255, 255, 255, 0.5);
  }
  .swal2-close {
    &:focus {
      box-shadow: none;
    }
  }
}
.theme-toaster-container {
  margin: 10px 0px 0px 0px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  .icon {
    width: 35px;
    text-align: left;
    padding-left: 5px;
  }
}
/* ========== Toaster End =========*/

/* ========== Loader Start =========*/
.loader {
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  .spinner-border {
    position: relative;
    width: 2.6rem;
    height: 2.6rem;
    left: calc(50% - 1.3rem);
    top: calc(50% - 1.3rem);
  }
}
/* ========== Loader End =========*/

.modal-popup-container {
  padding: 0 20px;
}
.form-group {
  display: block;
  margin-bottom: 20px;
  position: relative;
}
.modal-input {
  width: 100%;
  // height: 40px;
  font-weight: 400;
  font-size: 12px;
  // color: #b9b9b9;
  // background-color: white;
  // background-repeat: no-repeat;
  // background-position: right;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 10px 15px;
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: #b9b9b9;
  }
}

.page-header {
  padding: 30px 0;
}
.page-search {
  input {
    padding: 7px 35px 7px 12px;
    // min-width: 300px;
    font-size: 12px;
    background: #f4f7fb url("./../assests/icons/ic-search-16.svg") no-repeat
      calc(100% - 10px) 10px;
    border: 1px solid #e5e5e5;
    @include border-radius(8px, 8px, 8px, 8px);
    @include placeholder(#b9b9b9);
  }
  .search-button {
    height: 30px;
    width: 35px;
    background: #f4f7fb url("./../assests/icons/ic-search-16.svg") no-repeat
      center;
    margin-top: -10px;
    top: 6px;
    position: relative;
    border: 1px solid #e5e5e5;
    @include border-radius(8px, 8px, 8px, 8px);
  }
}
.page-filter-button {
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 400;
  color: $primaryFontColor;
  text-align: left;
  border: 1px solid #3e3e3e !important;
  background-color: white;

  @include border-radius(50px, 50px, 50px, 50px);
  min-width: 110px;
  img {
    margin-right: 2px;
  }
  &.down-arrow {
    background: #f4f7fb url("./../assests/icons/ic-chevron-down-16.svg")
      no-repeat calc(100% - 10px) center;
  }
  &.up-arrow {
    background: #f4f7fb url("./../assests/icons/ic-chevron-up-16.svg") no-repeat
      calc(100% - 10px) center;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
}
.page-filter-button:hover {
  background-color: #f4f7fb;
}

.dialog-background-pink {
  background: linear-gradient(109.79deg, #fff9fc 25.07%, #fff7f4 83.56%);
  background-size: cover;
}
.dialog-background-blue {
  background: linear-gradient(291.04deg, #f9fcff 11.26%, #f4f5ff 66.8%);
}
.dialog-background-blue2 {
  background: linear-gradient(109.79deg, #f3fff5 25.07%, #e9fffe 83.56%);
  .add-preferences-banner {
    position: relative;
    overflow: hidden;
    padding-right: 20px;
    @include linear-gradient(to bottom right, #f5f3ff, #e2eaff);
    @include border-radius(16px, 16px, 16px, 16px);
    .thumb {
      width: 170px;
    }
    .actions {
      .button {
        .btn-rounded {
          padding: 12px 40px;
          @include media-breakpoint-down(sm) {
            padding: 10px 25px;
          }
        }
      }
      .add-preferences-banner {
        position: relative;
        overflow: hidden;
        padding-right: 20px;
        @include linear-gradient(to bottom right, #f5f3ff, #e2eaff);
        @include border-radius(16px, 16px, 16px, 16px);
        .thumb {
          width: 170px;
        }
        .actions {
          .button {
            .btn-rounded {
              padding: 12px 40px;
              @include media-breakpoint-down(sm) {
                padding: 10px 25px;
              }
            }
            @include media-breakpoint-down(lg) {
              padding: 20px;
            }
            @include media-breakpoint-down(md) {
              .actions {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}

// candidate-found-initials-box

.candidate-found-initials-box {
  height: 48px;
  width: 48px;
  font-size: 14px;
  border-radius: 50%;
  color: #ffffff;
}

.cursor-text {
  cursor: text;
}

.go-back-btn:focus {
  border: none;
}
.go-back-btn:hover {
  border: 1px solid black;
}

.add-later-link {
  text-decoration: underline;
}
.add-later-link:hover {
  color: rgb(48, 48, 225);
}

.btn-disabled-registration:hover {
  cursor: not-allowed;
}

.btn-disable {
  cursor: not-allowed;
  opacity: 50%;
  background-color: #171616;
}

.text-underline {
  text-decoration: underline;
}

.fs-16 {
  font-size: 16px;
}

.otp-resend-link:hover {
  transition: 300ms;
  color: #0b44d8 !important;
}

.color-saffron {
  color: #ff3d12;
}

.color-gray {
  color: #808080;
}

.container-main {
  margin: 1rem 0rem 1rem 1rem;
}

.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-65 {
  width: 65%;
}

.w-60 {
  width: 60%;
}
.w-55 {
  width: 55%;
}
.w-30 {
  width: 30%;
}

.rounded-corner-none {
  @include media-breakpoint-down(md) {
    border-radius: 0px !important;
  }
}

.modal-header-custom {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.w-fit {
  width: fit-content !important;
}
.h-fit {
  height: fit-content;
}

.card-parent-bg-none:hover {
  .onhover-show2 {
    display: flex !important;
    align-items: center;
    top: 0px;
    bottom: 0px;
  }
}

.onhover-show2 {
  display: none !important;
}
.card-parent {
  position: relative !important;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.card-parent:hover {
  background: #f4f7fb;
  .onhover-show {
    display: flex !important;
    position: absolute;
    align-items: center;
    top: 0px;
    bottom: 0px;
  }
  .onhover-show3 {
    display: flex !important;
    flex-wrap: nowrap;
    white-space: nowrap;
    position: absolute;
    top: 0px;
    bottom: 0px;
    justify-content: center !important;
    align-items: center !important;
    right: 30px;
  }
}
.on-hover-show-item {
  display: none;
}
.onhover-show {
  display: none;
}

.onhover-show3 {
  display: none;
}

.onhover-show-admin-jobs {
  display: none;
}

.admin-jobs-md-cards:hover {
  .onhover-show-admin-jobs {
    display: block !important;
  }
}

.min-width-100 {
  min-width: 100px;
}
.min-width-90 {
  min-width: 90px;
}
.min-width-80 {
  min-width: 80px;
}
.min-width-70 {
  min-width: 70px;
}
.min-width-60 {
  min-width: 60px;
}
.h-40 {
  height: 40px !important;
}
.h-35 {
  height: 35px !important;
}
.h-30 {
  height: 30px !important;
}
.h-95 {
  height: 95% !important;
}
.modal-fullscreen > .modal-content {
  border-radius: 0px !important;
}

.modal-fullscreen-lg-down .modal-content {
  @include media-breakpoint-down(lg) {
    border-radius: 0px !important;
  }
}
.modal-fullscreen-md-down .modal-content {
  @include media-breakpoint-down(md) {
    border-radius: 0px !important;
  }
}
.content-margin {
  padding: 0 0rem 0 3rem !important;
  @include media-breakpoint-down(lg) {
    padding: 0 0rem !important;
  }
}

.modal-fullscreen,
.modal-fullscreen-lg-down,
.modal-fullscreen-md-down,
.modal-fullscreen-sm-down {
  height: auto !important;
}

.modal-close-wrapper {
  position: relative;
  top: -15px;
  @include media-breakpoint-down(sm) {
    top: 0;
  }
}

.col-vertical-gap {
  height: calc(100% - 25px);
}

.h-54 {
  height: 54px !important;
}

.page-search-custom {
  input {
    padding: 7px 35px 7px 12px;
    // min-width: 300px;
    font-size: 12px;
    background: #f4f7fb url("./../assests/icons/ic-search-16.svg") no-repeat
      calc(100% - 10px);
    border: 1px solid #e5e5e5;
    @include border-radius(8px, 8px, 8px, 8px);
    @include placeholder(#b9b9b9);
  }
}

.modal-fixed-height > .modal-content {
  height: 500px !important;
  @include media-breakpoint-down(lg) {
    height: auto !important;
  }
}
.header-ellipse-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.account-name-ellipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.all-users-table-turncate-width {
  width: 200px;
  @include media-breakpoint-down(xxl) {
    width: 150px;
  }
  @include media-breakpoint-down(xl) {
    width: 100px;
  }
}
.all-users-small-turncate-width {
  width: 500px;
  @include media-breakpoint-down(md) {
    width: 350px;
  }
  @include media-breakpoint-down(sm) {
    width: 200px;
  }
}

.empty-pin-container {
  height: 175px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    height: 100px;
  }
}

.overview-name {
  max-width: 350px;
  @include media-breakpoint-down(sm) {
    max-width: 250px;
  }
}

.header-name-width {
  max-width: 150px;
  max-height: 21px;
}

@media screen and (max-width: 375px) {
  .header-name-width {
    max-width: 95px;
  }
}

.login-btn-focus {
  &:focus {
    border: 1.5px solid black !important;
  }
}

.pass-btn-focus {
  &:focus {
    border: 1.5px solid black !important;
  }
}

.invite-btn-focus {
  &:focus {
    border: 2px solid rgb(230, 54, 10) !important;
  }
}

.acc-btn-focus {
  &:focus {
    border: 2px solid rgb(230, 54, 10) !important;
  }
}
.user-btn-focus {
  &:focus {
    border: 1.5px solid rgb(71, 162, 204) !important;
  }
}
.jobs-btn-focus {
  &:focus {
    border: 2px solid rgb(71, 162, 204) !important;
  }
}
.referee-btn-focus {
  &:focus {
    border: 2px solid rgb(71, 162, 204) !important;
  }
}
.notification-dropdown {
  .dropdown-toggle::after {
    display: none !important;
  }
}
.dropdown {
  .dropdown-toggle::after {
    display: none !important;
  }
}
