.seikor-admin-action-card-position {
  top: 63px;
  right: 175px;
}
.admin-btns-border {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}
.seikor-admin-access-bg {
  background: #f4f7fb;
  border-radius: 4px;
}
