@import "../../../styles/mixins.scss";
@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.salutation {
  background: linear-gradient(131.54deg, #78f7ff -21.29%, #3b59c4 126.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 20px;
  font-weight: 400;
}
.numbersOfView {
  border: 1px solid #eb1260;
  background-color: #eb1260;
  color: white;
  width: 42px;
  height: 18px;
  padding-left: 13px;
  border-radius: 4px;
}
.zeroView {
  border: 1px solid #b9b9b9;
  background-color: #b9b9b9;
  color: white;
  width: 42px;
  height: 18px;
  padding-left: 13px;
  border-radius: 4px;
}
.outer-border {
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}
.outer-border-without-radius {
  border: 1px solid #e5e5e5;
}
.w-33 {
  width: 32%;
}
.border-8 {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  // width: 280px;
  // @include media-breakpoint-up(xl) {
  //   width: 333px;
  // }
}
.dashboard-teams-btn {
  width: 156px;
  height: 40px;
}
.horizontal-scroll-area {
  // width: calc(1140px - 255px);
  overflow: hidden;
  overflow-x: hidden;
  padding-bottom: 5px;
  @include media-breakpoint-down(md) {
    overflow: hidden;
    overflow-x: auto;
  }
  // @media (min-width: 1400px) {
  //   width: calc(1140px - 212px);
  // }
}
.horizontal-scroll-area:hover {
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 5px;
}
.org-roles-performance {
  margin-bottom: 20px;
  .stats {
    border-left: 1px solid #e5e5e5;
    margin-bottom: 20px;
    width: 100px;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      width: 250px;
    }
    @include media-breakpoint-down(xl) {
      &:last-child {
        width: 300px;
        border-left: none;
      }
    }
    @include media-breakpoint-down(lg) {
      width: 50px;
      &:last-child {
        width: 240px;
        border-left: 1px solid #e5e5e5;
      }
    }
    @include media-breakpoint-down(md) {
      width: 100px;
      &:last-child {
        width: 250px;
        border-left: none;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
      &:nth-child(3) {
        border-left: none;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
}

.org-switchbutton-wrapper {
  font-size: 12px;
  @include media-breakpoint-up(sm) {
    font-size: inherit;
  }
}

.insight-cards-height {
  min-height: 150px;
  @include media-breakpoint-down(sm) {
    min-height: auto;
  }
}
.insight-avg-joining-height {
  height: 175px;
}
// .org-switchbutton-wrapper {
//   width: 480px;
//   @media screen and (max-width: 500px) {
//     width: 100%;
//   }
// }

@media screen and (max-width: 768px) {
  .mobile-margin {
    margin: 0rem 2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .mobile-margin {
    margin: 0px 0px 0px 0px !important;
  }
}

// .funnel-graph-wrapper {
//   min-width: 900px;
//   overflow: hidden;
//   overflow-x: auto;
// }
// .funnel-graph-width {
//   min-width: 1000px !important;
// }

.org-referee-nm-width {
  max-width: 150px;
  @include media-breakpoint-down(xl) {
    max-width: 190px;
  }
  @include media-breakpoint-down(md) {
    max-width: 150px;
  }
   @include media-breakpoint-down(sm) {
    max-width: 120px;
  }
}
