@import "../../../index.scss";
.date-show {
  background: #a8bde4;
  padding: 5px 12px;
  border-radius: 27px;
  font-weight: 600;
  font-size: 10px;
  color: #3e3e3e;
}
.left-data {
  font-weight: 400;
  font-size: 12px;
  color: #3e3e3e;
  width: 80%;
}
.right-data {
  font-weight: 400;
  font-size: 12px;
  color: #3e3e3e;
  padding-left: 80px;
  text-align: right;
}

.action-card {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background: transparent;
  padding: 15px;
  opacity: 0;
  .link {
    a {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.card-parent:hover {
  background: #f4f7fb;
  .action-card {
    opacity: 1;
  }
}

.back-btn-width {
  width: 200px !important;
}

.referrals-blue-div {
  width: 33.33%;
  background-color: #3d81ff;
}
.applications-yellow-div {
  width: 33.33%;
  background: #ffd027;
}
.joinees-green-div {
  width: 33.33%;
  background: #12bd6b;
}
.div-width {
  width: 33.33%;
}
