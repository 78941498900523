@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../../node_modules//bootstrap/scss/bootstrap.scss";

.action-card {
  position: absolute;
  background: transparent;
  opacity: 0;
}
.startScratch:hover {
  .scratch-btn {
    display: flex !important;
  }
}
.scratch-btn {
  display: none !important;
}

.card-parent:hover {
  background: #f4f7fb;
  .action-card {
    opacity: 1;
  }
  .manage-btn {
    display: flex !important;
  }
}
.manage-btn {
  display: none !important;
}
.light-cool {
  background-color: #f4f7fb !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-top: 5px !important;
  &.green {
    background-color: #12bd6b;
  }
  &.yellow {
    background-color: #ffd027;
  }
  &.gray {
    background-color: #b9b9b9;
  }
}
.radius12 {
  border-radius: 12px !important;
}
.radius19 {
  border-radius: 19px !important;
}
.radius16 {
  border-radius: 16px !important;
}
.radius8 {
  border-radius: 8px;
}
.radius0 {
  border-radius: 0px !important;
}
.radius4 {
  border-radius: 4px !important;
}
.modal-content {
  border-radius: 16px !important;
}
.h-28 {
  height: 28px;
  align-items: center;
}

.job-main-modal {
  position: absolute;
}
.astrick {
  color: #eb1260;
}
.btn-outline-none:focus {
  border: none;
}
.btn-outline-none:active {
  border: none;
}

.sort-modal {
  position: initial;
}
.sort-modal-content {
  position: fixed;
  bottom: -5px;
  left: -2px;
  border-radius: 15px 15px 0px 0px !important;
  right: -2px;
  width: auto;
}
.h-48 {
  height: 48px;
  display: flex;
  align-items: center;
}
input[type="checkbox"] {
  accent-color: black;
}

.job-post-search {
  width: 100%;
  justify-content: end;
}

.num-roles-width {
  width: 32%;
  @include media-breakpoint-down(md) {
    width: 48%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .search-bar {
    width: 50% !important;
  }
  .mobile-margin {
    margin: 0rem 2rem !important;
  }
  .card-main {
    margin-top: 20px !important;
    margin-bottom: 4rem !important;
  }
}
@media screen and (min-width: 768px) {
  .card-main {
    margin-top: 20px !important;
    margin-bottom: 4rem !important;
  }
}

@media screen and (max-width: 400px) {
  .new-job-post-btn {
    padding-right: 3.4rem;
    margin-bottom: 1rem;
  }
  .new-job-post {
    width: 100% !important;
  }
  .new-job-template {
    width: 100% !important;
  }
  .mobile-margin {
    margin: 0px 0px 0px 0px !important;
  }
  .search-bar {
    width: 100% !important;
  }
  .job-post-by {
    width: 100% !important;
  }
  .d-sm-mt-4 {
    margin-top: 20px;
  }
  .page-search {
    width: 100% !important;
  }
  .page-search-inner {
    width: 100% !important;
  }
}
.search-quick-selection {
  display: flex;
  gap: 1.5rem;
  align-items: start;
  @include media-breakpoint-up(md) {
    flex-direction: column-reverse !important;
    gap: 1rem;
  }
}
.pin-bg {
  min-width: 40px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
}
.disable-link {
  pointer-events: none;
}
