.feedback-background {
  background: #f4f7fb;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.shadow-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.pink-color-badge {
  color: #eb1260;
  font-weight: 400;
  font-size: 12px;
  background: #fff7fa;
  border-radius: 4px;
  padding: 5px 8px;
}
.blue-color-badge {
  color: #0578ff;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 8px;
  background: #eff6ff;
  border-radius: 4px;
}
.gray-color-badge {
  color: #3e3e3e;
  font-weight: 400;
  font-size: 12px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 5px 8px;
}
hr {
  background: #e5e5e5;
  height: 1px;
}
.chat-box-border {
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-profile-width {
  width: 124px !important;
}
.dot-icon-position {
  right: 5px;
  top: -3px;
}
.btn-black-bg {
  background: #1c1c1c;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  padding: 12px 20px;
}
.btn-copy-payment {
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  background: #ffffff;
  color: #1c1c1c;
  font-weight: 700;
  font-size: 12px;
  padding: 13px 35px;
}
