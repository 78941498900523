@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.join-invite-input {
  height: 40px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}
.invite-close-icon-pos {
  top: 15px;
  right: 25px;
}
.password-show-pos {
  top: 40px;
  right: 10px;
}
.invite-seikorlogo-pos {
  top: 60px;
  left: 50px;
}
.lhs-bg-picture {
  min-height: 600px;
  position: relative;
  background-image: url(../assests/join-via-invite-image.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.lhs-bg-black {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}
