@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../styles/variables.scss";

.account-info-box {
  height: 300px;
  width: 320px;
  background: linear-gradient(359.55deg, #f5f3ff -0.64%, #e2eaff 93.37%);
  border-radius: 8px;
}
.user-profile-picture {
  height: 64px;
  width: 64px;
  overflow: hidden;
}
.edit-icon-div {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 8px;
  right: 6px;
  background-color: white;
  img {
    position: absolute;
    top: 4px;
    left: 4px;
  }
}
.edit-name-icon {
  width: 18px;
  height: 18px;
  background-color: white;
  position: relative;
  img {
    position: absolute;
    top: 4px;
    left: 4px;
  }
}
.btn-add-number {
  border: 1px solid #1c1c1c !important;
  padding: 0.5rem 2rem !important;
}
.btn-save-number {
  background-color: #1c1c1c !important;
  color: white !important;
  padding: 0.5rem 2rem !important;
}
.email-switch {
  width: 20px;
  height: 10px;
}
.form-check-input {
  // border-color: #1c1c1c !important;
  &:checked {
    background-color: #1c1c1c !important;
    border-color: #1c1c1c !important;
  }
  &:active {
    border-color: #1c1c1c !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.switch-btn-small {
  height: 10px !important;
  width: 20px !important;
}
.select-style {
  width: 18% !important;
}
.number-dialog-close {
  position: absolute;
  right: 2px;
  bottom: 32px;
}
.username-dialog-close {
  position: absolute;
  right: 2px;
  bottom: 16px;
}
.user-name {
  padding-right: 80px;
  max-width: 190px;
  max-height: 48px;
  // @include media-breakpoint-down(sm) {
  //   max-width: 125px;
  // }
}
.change-pswd-input-profile {
  border: 1px solid gray;
  height: 40px;
  width: 320px;
}
.modalLabel {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.dialog-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 119%;
  color: #1c1c1c;
}
.modal-input {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 10px 15px;
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: #b9b9b9;
  }
}
.username-buttons {
  .btn-save {
    font-size: 12px;
    font-weight: 700;
    padding: 8px 24px;
    color: white;
    background: black;
  }
  .btn-cancel {
    border: 1px solid #1c1c1c;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 17px;
    color: black;
    background: white;
  }
  .btn-cancel:hover {
    background: white;
    color: black;
  }
}
.account-country-code {
  width: 25% !important;
}
.account-notification-pref {
  width: 320px;
}
.account-hr-color {
  color: #b9b9b9;
  opacity: 0.45;
}

@media (max-width: 575px) {
  .account-country-code {
    width: 40% !important;
  }
  .account-info-box {
    width: 100%;
  }
  .account-notification-pref {
    width: 100%;
  }
}
