@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
.all-orgs-red-btn {
  color: #eb1260 !important;
  border: 1px solid #eb1260 !important;
}
.all-orgs-red-color {
  color: #ee2e22;
}
.add-org-btn {
  height: 40px;
  width: 156px;
  border-radius: 2rem !important;
}
.orgs-action-card-position {
  top: 64px;
  right: 260px;
}
.org-hover-btn {
  width: 110px !important;
}
.admin-orgs-search-input {
  width: 333px;
  background: #f4f7fb;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.org-logo-upload-div {
  width: 80px;
  height: 80px;
  background: #f4f7fb;
  border: 1px dashed #808080;
  border-radius: 8px;
}

.all-orgs-box-shadow {
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.14) !important;
}

.pin-bg-all-orgs {
  min-width: 32px !important;
  min-height: 32px !important;
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  @include media-breakpoint-down(md) {
    max-width: 45px !important;
    max-height: 45px !important;
  }
}

@media screen and (max-width: 768px) {
  .admin-orgs-search-input {
    width: 100%;
  }
  .admin-orgs-search-input-div {
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .fs-sm-16 {
    font-size: 16px !important;
  }
}

.all-org-btn-outline-dark {
  border: 1px solid #212529 !important;
  background-color: #fff !important;
  :hover {
    background-color: #fff !important;
  }
}

.all-org-card-pos {
  bottom: 0;
  position: absolute;
}
