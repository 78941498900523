/* --- */

html {
  height: 100%;
}

body {
  height: 100%;
  background: #f4f7fb;
  font-family: "Poppins" !important;
}

#root {
  height: 100%;
}

.page-container {
}
