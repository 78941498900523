@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

.green {
  background-color: #12bd6b;
}
.light-green {
  background-color: #2fff9b;
}
.pink {
  background-color: #eb1260;
}
.blue {
  background-color: #3d81ff;
}
.brown {
  background-color: #721212;
}
.yellow {
  background-color: #ffd027;
}
.black {
  background-color: black;
}
.progress-bar-height {
  height: 26px;
}
.funnel-error-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background: #f4f7fb;
  border: 2px dashed #b9b9b9;
  border-radius: 8px;
}
.legends-width {
  width: 100px;
  @include media-breakpoint-up(sm) {
    width: 200px;
  }
  @include media-breakpoint-up(md) {
    width: 250px;
  }
  @include media-breakpoint-up(md) {
    width: auto;
  }
}
.tooltip-style {
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
}
.tooltip-number {
  font-weight: 300;
  font-size: 9px;
  color: #ffffff;
  text-align: left;
}
