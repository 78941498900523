@import "../../../index.scss";
.subpart-div {
  border-bottom: 4px solid #e5e5e5;
  max-width: 220px;
  flex: 1;
  cursor: pointer;
}
.subpart-div-after {
  background: #f4f7fb;
  border-bottom: 4px solid #232323 !important;
  border-bottom: 4px solid #e5e5e5;
  max-width: 220px;
  flex: 1;
  cursor: pointer;
}

.application-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background: #ffffff;
  position: relative !important;
  .application-hover-card {
    position: absolute;
    display: none;
  }
}
.application-card:hover {
  .application-hover-card {
    position: absolute;
    display: block;
    bottom: 10px;
    width: 90% !important;
    padding: 5px;
  }
}
hr {
  background: #e5e5e5 !important;
  height: 1px !important;
}
.pink-color-badge,
.package {
  color: #eb1260;
  font-weight: 400;
  font-size: 12px;
  background: #fff7fa;
  border-radius: 4px;
  padding: 5px 8px;
}
.gray-color-badge {
  color: #3e3e3e;
  font-weight: 400;
  font-size: 12px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 5px 8px;
}
.blue-badge,
.location {
  color: #0578ff;
  background: #eff6ff;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 12px;
}
.new-badge-div {
  background: linear-gradient(154.48deg, #eb1260 13.72%, #ff3d12 83.84%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
}
.unassigned-badge-div {
  background: linear-gradient(180deg, #ffcf52 0%, #ffab48 100%);
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  color: #1c1c1c;
}
.gray {
  color: #3e3e3e;
  background: #f8f8f8;
}
.down-btn-remove {
  padding: 3.3px 6px;
  border-radius: 1px;
  .dropdown-toggle::after {
    display: none !important;
  }
}
.down-btn-remove:hover {
  background-color: #f4f7fb;
}

.border-box {
  background: #ffffff;
  border: 1px solid #3e3e3e !important;
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 200px;
  right: -10px !important;
}
.btn-view-application {
  padding: 12px 0px;
  border-radius: 8px;
}
.page-filter-button {
  border: 1px solid #3e3e3e !important;
  &.down-arrow {
    background: #f4f7fb url("./../../../assests/icons/ic-chevron-down-16.svg")
      no-repeat calc(100% - 10px) center !important;
  }
}

.search-btn {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #b9b9b9;
  // background: #f4f7fb;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  background: #f4f7fb url("./../../../assests/icons/ic-search-16.svg") no-repeat
    calc(100% - 10px);
}

.fixed-filter-div {
  background: #f4f7fb;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
.bg-main-application {
  background: linear-gradient(359.27deg, #f4f7fb 44.03%, #ffffff 100.87%);
  height: 100% !important;
  width: 100%;
}
.margin-half {
  // margin-top: 300px !important;
  // height: 100% !important;
  overflow: auto !important;
  z-index: 1056 !important;
}
.bg-company-details {
  background: linear-gradient(
    109.79deg,
    #f5f3ff 25.07%,
    #e2eaff 83.56%
  ) !important;
  width: 56px !important;
  height: 100%;
  position: fixed;
  z-index: 10;
  @include media-breakpoint-up(xl) {
    left: 0px;
  }
  @include media-breakpoint-down(lg) {
    background: none !important;
    width: 0px !important;
  }
}
.company-details-width {
  background: linear-gradient(
    109.79deg,
    #f5f3ff 25.07%,
    #e2eaff 83.56%
  ) !important;
  width: 400px;
  padding-left: 55px;
  padding-right: 55px;
  position: fixed;
  z-index: 10;
  max-height: calc(100vh - 2rem);
  transition: all 1s;
  overflow-y: scroll;
  @include media-breakpoint-up(xl) {
    left: 0px;
  }
  @include media-breakpoint-down(lg) {
    position: fixed !important;
    width: 100% !important;
    z-index: 13 !important;
    padding-bottom: 50px;
  }
}

.company-section-width {
  width: 400px;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}
.btn-size {
  width: 48px;
  height: 48px;
}
.right-arrow-btn-position {
  position: fixed !important;
  bottom: 10%;
  z-index: 99 !important;
  @include media-breakpoint-down(lg) {
    display: block !important;
    position: fixed !important;
    bottom: 10% !important;
    left: 90% !important;
    z-index: 99 !important;
    transform: rotate(-90deg);
    left: auto;
  }
  @include media-breakpoint-down(sm) {
    left: 80% !important;
  }
}

.experience {
  color: #3e3e3e;
  font-weight: 400;
  font-size: 12px;
}
.company-section-less-width {
  @include media-breakpoint-down(xxl) {
    margin-left: 90px;
  }
  @include media-breakpoint-down(lg) {
    margin-left: 0px;
  }
}
.btn-black-text {
  background: #1c1c1c;
  border-radius: 8px;
  font-weight: 600;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 118px;
  color: white;
}
.hide-details-text {
  @include media-breakpoint-down(md) {
    transform: rotate(90deg);
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.download-option {
  background: #f4f7fb;
  color: #1c1c1c;
  font-weight: 600;
  font-size: 14px;
}
.rounded-bottom {
  border-bottom-right-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}
.select-for-export {
  background: #f8f8f8;
}
.bg-green-icon {
  background: #12bd6b;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.bg-gray-icon {
  background: #808080;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.side-margin-width {
  margin-left: -14px;
  margin-right: -14px;
}
.btn-download-disabled {
  background: #b9b9b9 !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 11px !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 118px;
  color: white !important;
}
.insights-cards {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.insight-filter {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  font-weight: 400;
  font-size: 12px;
  color: #1c1c1c;
  min-width: 120px;
  text-align: center;
}
.action-cards {
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
}
.btn-manage-action {
  padding: 12px 55px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  background: #1c1c1c;
  border-radius: 8px;
}
.btn-notification-disabled {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  background: #b9b9b9;
  border-radius: 8px;
}
.btn-close-action {
  padding: 12px 55px;
  font-weight: 700;
  font-size: 12px;
  color: #eb1260;
  background: #ffffff;
  border: 1px solid #eb1260;
  border-radius: 8px;
  text-align: center;
}
.btn-invite {
  padding: 10px 55px !important;
}
.textarea-msg-box::before,
.textarea-msg-box::after {
  box-sizing: none !important;
}
.status-padding-right {
  padding-right: 300px !important;
  @include media-breakpoint-down(lg) {
    padding-right: 100px !important;
  }
  @include media-breakpoint-down(md) {
    padding-right: 0px !important;
  }
}
.action-shadow-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.btn-min-width {
  min-width: 100px !important;
  color: #1c1c1c !important;
}
.input-year-btn {
  background: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  width: 70px !important;
  height: 40px !important;
}
.btn-empty-white {
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  color: #1c1c1c;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 118px;
  font-weight: 700;
  font-size: 12px;
}
.table-component {
  tr {
    .hover-btns {
      display: none !important;
      position: absolute;
      right: 20%;
    }
  }
  tr:hover {
    background: #f4f7fb;
    border-radius: 5px;
    .hover-btns {
      display: block !important;
      position: absolute;
      right: 20%;
      button {
        margin-right: 8px;
      }
    }
  }
}
.action-card-btn {
  .hover-btns {
    display: none !important;
    position: absolute;
    bottom: 0px;
  }
}
.img-dotted-border {
  border: 1px dashed #808080 !important;
  border-radius: 8px;
  height: 80px !important;
}
.input-date-type {
  background: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px !important;
  padding: 10px 20px;
  color: #b9b9b9 !important;
}
.application-dialog-shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}
@media screen and (max-width: "575px") {
  .send-notification-wrapper {
    width: 100% !important;
  }
  .send-notification-btns {
    width: 100% !important;
  }
}
@media screen and (max-width: "400px") {
  .cancel-download-btn-layout {
    flex-direction: column;
    button {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: "450px") {
  .select-for-download-btns {
    flex-direction: column;
    button {
      width: 100% !important;
    }
  }
}
