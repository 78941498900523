@media screen and (max-width: 575px) {
  .sm-radius-0 {
    border-radius: 0px !important;
  }
}
.pin-bg-admin-jobs {
  min-width: 36px;
  min-height: 36px;
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
}
.hover-bg {
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.5), #fff);
}
.md-cards-btn-width {
  width: 36px !important;
}
