@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./styles/variables.scss";
@import "./styles/common.scss";
@import "./styles/styles.scss";

// Common Component Scss
@import "./styles/text.scss";
@import "./styles/form.scss";
@import "./styles/buttons.scss";
@import "./styles/cards.scss";
@import "./styles/cursors.scss";
@import "./styles/badges.scss";
@import "./styles/profile_pic.scss";
@import "./styles/otp_input.scss";
@import "./styles/filter_dialog.scss";
@import "./styles/header.scss";
@import "./styles/notificationdropdown.scss";
@import "./styles/dialog.scss";
@import "./styles/toggle_switch.scss";
@import "./styles/notfoundpage.scss";

@import "./styles/navigation_sidebar.scss";
@import "./styles/jobdetails.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}