@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.title-cards {
  height: 145px;
  min-width: 150px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.referee-filter-button {
  width: 110px !important;
  border: 1px solid black !important;
  border-radius: 2rem !important;
  background-color: #fff !important;
  color: black;

  &:hover {
    background-color: #dee2e6 !important;
  }
  &:focus {
    background-color: #dee2e6 !important;
  }

  .dropdown-toggle::after {
    display: none;
  }
}
.referee-search-input {
  width: 298px;
  background: #f4f7fb;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.referee-table {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  tr:last-child td {
    border: 0px !important;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
.action-card-referee-position {
  top: 10px !important;
  right: 10px !important;
  left: auto !important;
  padding: 0px !important;

  // @include media-breakpoint-down(xl) {
  //   top: 36px !important;
  //   right: 10px !important;
  // }
}
.referee-search-icon-position {
  position: absolute;
  top: 12px;
  right: 12px;
}
.small-referee-cards {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  .referee-small-card-btn {
    position: absolute;
    display: none;
  }
}
.small-referee-cards:hover {
  .referee-small-card-btn {
    position: absolute;
    display: block;
    bottom: 0;
  }
}
.hover-bg {
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.5), #fff);
}
.bonus-received {
  min-height: 70px;
  margin-top: 52px !important;
}
.referee-fixed-sort-div {
  background: #f4f7fb;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  // margin-left: -15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.referee-bar-chart-width {
  width: 400px;
  margin: 0 auto;

  @include media-breakpoint-between(lg, xl) {
    margin-top: 1rem;
  }

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}

.referee-table-small-display {
  @include media-breakpoint-down(md) {
    display: block;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.referee-table > .mt-0 > .w-100 > tr > th {
  padding: 15px 0 !important;
}

@include media-breakpoint-down(sm) {
  .referee-search-input,
  .referee-search-input-div {
    width: 100%;
  }
  .title-cards {
    min-width: auto !important;
  }
  .referee-bar-chart-width {
    width: 100%;
  }
}
@include media-breakpoint-up(sm) {
  .referee-detail-graph {
    border-bottom: 1px solid #dee2e6;
  }
}
