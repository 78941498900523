.title-card {
  line-height: 19.04px;
  padding-top: 7px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 7px;
}
.card-badge {
  background-color: #f1f1f1;
  font-size: 12px;
  color: #808080;
  border-radius: 5px;
  line-height: 18px;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  opacity: 0.9;
  font-weight: 400;
}

.card-badge2 {
  background-color: #f8f8f8;
  font-size: 12px;
  color: #3e3e3e;
  border-radius: 5px;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  opacity: 0.9;
  font-weight: 400;
  line-height: 3;
}

.card-badge-blue {
  background-color: #eff6ff;
  font-size: 12px;
  color: #0578ff;
  border-radius: 5px;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  opacity: 0.9;
  font-weight: 400;
  line-height: 3;
}

.card-subtitle {
  font-size: 14px;
  font-weight: 400;
}

.card-content {
  color: rgb(128, 128, 128);
  padding-bottom: 10px;
  font-size: 12px;
}
.card-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e5e5;
}

.card-parent-container {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
}

.card-parent-container-null {
  // display: flex;
  // justify-content: space-between;
  // border: 1px solid #e5e5e5;
  // flex-direction: column;
  // border-radius: 8px;
  // box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  // padding: 5rem;
  // width: 80%;
  // text-align: center;
  // color: #808080;
  // margin: 10px;
  // margin-left: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
  padding: 5rem;
  width: 100%;
  text-align: center;
  color: #808080;
  @include media-breakpoint-down(md) {
    width: 100% !important;
    margin: 0px !important;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  p {
    margin: 0px !important;
  }
}
