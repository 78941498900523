.form-label {
  color: $secondaryFontColor;
  font-weight: 600;
  font-size: 14px;
}

.form-control {
  box-shadow: none;

  &.error {
    color: $errorRed;
    border-color: $errorRed;
  }
}
.modal-form-input {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #b9b9b9;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 0.575rem 0.75rem;
}

.modal-form-input-select {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #b9b9b9;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 0.5rem 0.2rem;
}

.form-row {
  margin-bottom: 24px;
}

.field-error {
  color: $errorRed;
  text-align: right;
  font-weight: 600;
  font-size: 11px;
}

.form-control-hints {
  font-weight: 400;
  font-size: 11px;
  color: $tertiaryFontColor;
}

.combo-search-box {
  background: url("../assests/icons/ic-combo-arrow.svg") no-repeat
    calc(100% - 15px) center;
  background-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
  p {
    margin-bottom: 0px;
  }
}

.combo-list-wrapper {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px 0;
  position: absolute;
  left: 0;
  right: 0;
  li {
    p {
      margin-bottom: 0px;
    }
    font-size: 12px;
    color: #1c1c1c;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-right: 7px;
    .icon {
      width: 38px;
      text-align: center;
    }
    .option-name {
      flex: 1;
      padding: 8px 6px;
      border-radius: 2px;
      &:hover {
        background-color: #f4f7fb;
      }
    }
    &.selected {
      .option-name {
        font-weight: 700;
      }
    }
  }
}
.combo-close-list {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  background: url("../assests/icons/cross-icon.svg") no-repeat center;
  background-size: 15px;
  cursor: pointer;
  right: 0;
  top: 0;
}
.combo-clear-search-text {
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 4px;
  z-index: 1000;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-align: center;
}
.combo-multi-selected-output {
  margin-top: 10px;
  & li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  display: flex;
  flex-wrap: wrap;
  .badge-wrapper {
    p {
      margin-bottom: 0px;
    }
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff7fa;
    border-radius: 4px;
    padding: 3px 0px 3px 8px;
    color: #eb1260;
    &.blue {
      background-color: #eff6ff;
      color: #0578ff;
    }
    &.gray {
      background-color: #f8f8f8;
      color: #3e3e3e;
    }
    .name {
      font-size: 12px;
      font-weight: 400;
      flex: 1;
    }
    .close-badge {
      font-size: 18px;
      font-weight: 300;
      width: 20px;
      height: 20px;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.auto-complete-list-wrapper {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  li {
    font-size: 12px;
    color: #1c1c1c;
    font-weight: 500;
    cursor: pointer;
    .option-name {
      margin: 2px;
      padding: 7px 20px;
      border-radius: 4px;
      &:hover {
        background-color: #f4f7fb;
      }
    }
    &.selected {
      .option-name {
        background-color: #f4f7fb;
        font-weight: 700;
      }
    }
  }
}
.forgot-width {
  // width: 300px !important;
}
.forgot-round {
  border: 1px solid black;
}
.bottom-align {
  bottom: 10%;
}
.password-updated {
  background-color: #1c1c1c;
}
.close-border {
  border-width: 2px;
  border-style: solid;
  border-color: #808080 #808080 white white !important;
}
.email-font {
  color: #3e3e3e;
}

.search-select-input {
  background-image: url("../assests/icons/ic-search-16.svg");
  background-repeat: no-repeat;
  padding: 7px;
  background-position: 14px;
  border-radius: 20px;
  outline: none;
  width: 18rem;
  color: #808080;
  padding-left: 35px;
  border: 1px solid #ffffff;
}

.select-badge {
  background-color: #ffffff;
  color: #3e3e3e;
  box-sizing: border-box;
  padding: 7px;
  width: fit-content;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 48px !important;
  text-align: center;
  border-bottom: 1px solid #e5e5e5 !important;
}

.form-check-input:focus {
  border-color: grey;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.icons-span {
  margin-left: 278px;
  //  border-radius: 4px;
  // padding: 0.575rem 0.75rem;
  //  border: 1px solid #b9b9b9;
  display: flex;
  flex-wrap: nowrap;
  //  flex-direction: row-reverse;
  margin-top: -26px;

  @media (max-width: 360px) {
    margin-left: 90%;
  }
}
