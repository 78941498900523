.admin-referee-red-color {
  color: #eb1260;
}
.admin-referee-search-input {
  width: 298px;
  background: #f4f7fb;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .admin-referee-input-div {
    width: 100%;
  }
  .admin-referee-search-input {
    width: 100%;
  }
}
