@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.job-template-container {
  padding: 0rem 1rem 0rem 2rem;
}
.add-template-btn {
  height: 40px;
  width: 187px;
  border-radius: 2rem !important;
}
.template-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.color-red {
  color: #eb1260;
}
.job-template-dropdown {
  .dropdown-toggle::after {
    border: none;
  }
}

@include media-breakpoint-down(md) {
  .job-template-container {
    padding: 0 0 0 7px;
  }
}

@include media-breakpoint-down(sm) {
  .job-template-container {
    padding: 0 0 0 3px;
  }
}
