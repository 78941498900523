.count-badge {
  border-radius: 50%;
  background: #eb1260;
  color: white;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  width: 18px;
  height: 18px;
  font-weight: 600;
  font-size: 11px;
  //   flex-shrink: 0;
  //   flex-grow: 0;
}
